<template>
  <div v-if="index == 0">
    <el-slider
      id="horizontal"
      v-model="mutableSelect"
      :marks="marks"
      :step="1"
      :show-stops="false"
      :max="max"
      :min="min"
      :show-tooltip="false"
      :disabled="notEnabled"
      class="open-sans-regular fs-13"
    />
  </div>
  <div v-else>
    <el-slider
      id="horizontal"
      v-model="mutableSelect"
      :step="1"
      :show-stops="false"
      :max="max"
      :min="min"
      :show-tooltip="false"
      :disabled="notEnabled"
      class="open-sans-regular fs-13"
    />
  </div>
</template>

<script>
export default {
  name: 'Level',
  props: {
    question: String,
    marks: Object,
    index: Number,
    max: Number,
    min: Number,
    selected: Number,
    onClick: Function,
    notEnabled: Boolean,
  },
  data() {
    return {
      preventPOST: false,
      mutableSelect: this.selected,
    };
  },
  watch: {
    mutableSelect(newValue) {
      if (!this.notEnabled && !this.preventPOST) {
        this.onClick(newValue, this.index);
      }
      this.preventPOST = false;
    },
    selected(newValue) {
      this.preventPOST = true;
      this.mutableSelect = newValue;
    },
  },
};
</script>
